import http from '../http.js';

// 轮播图
function getAdsByMark(params) {
    return http.get('/Adszone/getAdsByMark', {
        params
    });
}
// 用户注册
function register(params) {
    return http.post('/User/register', params);
}
// 用户登录
function login(params) {
    return http.post('/User/login', params);
}
// 发送验证码
function send(params) {
    return http.post('/Sms/send', params);
}
// 短信列表
function smslist(params) {
    return http.get('/Buysms/smslist', {
        params
    });
}
// 购买短信
function submit(params) {
    return http.post('/Buysms/submit', params);
}
// 富文本
function richText(params) {
    return http.get('/Index/richText', {
        params
    });
}
// 文章列表
function articleList(params) {
    // Index/articleList

    return http.get('/index/article_index', {
        params
    });
}
// 文章详情
function articleInfo(params) {
    // Index/articleInfo
    return http.get('/index/artDetail', {
        params
    });
}

export default {
    getAdsByMark,
    register,
    login,
    send,
    smslist,
    submit,
    richText,
    articleList,
    articleInfo
}