<template>
	<div>
		<!-- 轮播图 -->
		<el-carousel :height="imgHeight+'px'" :interval="5000" style="z-index: -1;">
			<el-carousel-item v-for="(item,index) in bannerlist" :key="index">
				<img ref="imgHeight" style="width:100%;" :src="item.imageurl" mode="widthFix" />
			</el-carousel-item>
		</el-carousel>
		<!-- 公告 -->
		<div class="gg">
			<div class="con fc">
				<img src="../assets/image/gg.png" alt="">
				<div>{{ggcontent}}</div>
			</div>
		</div>
		<!-- 产品优势 -->
		<div class="advantage">
			<div class="title fcc">
				<img src="../assets/image/title1.png" alt="">
				<div>产品优势</div>
			</div>
			<div class="itembox">
				<div class="item fcs" v-for="(item,index) in advantagelist" :key="index">
					<img :src="item.imageurl" alt="">
					<div style="width: 224px;">
						<div class="t">{{item.title}}</div>
						<div class="p">{{item.describe}}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 产品特点 -->
		<div class="trait">
			<div class="con fcs">
				<div class="traititem">
					<img src="../assets/image/nav4.png" alt="">
					<div>产品优势特点</div>
				</div>
				<div class="traititem">
					<img src="../assets/image/nav5.png" alt="">
					<div>产品优势特点</div>
				</div>
				<div class="traititem">
					<img src="../assets/image/nav6.png" alt="">
					<div>产品优势特点</div>
				</div>
			</div>
		</div>
		<!-- 短信介绍 -->
		<div class="document fcs">
			<div class="documentitem" v-for="(item,index) in documentlist" :key="index">
				<div class="t">{{item.title}}</div>
				<img class="ioc" :src="item.imageurl" alt="" style="height: 72px;">
				<div class="p">{{item.describe}}</div>
				<div class="btn fcc">开发文档</div>
			</div>
		</div>
		<!-- 文章中心 -->
		<div class="article">
			<div class="title fcc">
				<img src="../assets/image/title2.png" alt="">
				<div>文章中心</div>
			</div>
			<div class=" frcs">
				<div class=" " v-for="(item,index) in newslist" :key="index" @click="godetails(item.id)">
					<div style="	display: flex;flex-direction: column;		margin:0 20px 20px 0 ;	text-align: center;">
						<!-- ../assets/image/xz.png -->
						<img :src="item.image" alt="" style="width: 280px;	height: 280px;	border-radius: 12px;" mode="aspectFill">
						<div class="stitle">{{item.title}}</div>
					</div>
				</div>
			</div>
			<!-- <div class="itembox frcs">
				<div class="item " v-for="(item,index) in newslist" :key="index" @click="godetails(item.id)">
					<div class="	display: flex;flex-direction: column;justify-content: center;">
					
						<img :src="item.image" alt="" style="width: 200px;" mode="aspectFill">
						<div class="name">{{item.title}}</div>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgHeight: '', //图片高度
				bannerlist: [], //轮播图列表
				ggcontent: '', //公告
				advantagelist: [], //产品优势
				documentlist: [], //短信分类
				newslist: [] //文章列表
			}
		},
		mounted() {
			this.getbanner();
			// 监听窗口变化，使得轮播图高度自适应图片高度
			window.addEventListener("resize", () => {
				this.getbanner();
			}, false);
		},
		destroyed() { //切换页面后销毁监听窗口事件
			window.removeEventListener("resize", this.getbanner, false);
		},
		created() {
			this.getggcontent();
			this.getadvantagelist();
			this.getdocumentlist();
			this.getnewslist();
		},
		methods: {
			//获取轮播图
			getbanner() {
				let data = {
					mark: 'index01'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.bannerlist = res.data.data
						//获取到图片数据之后
						this.$nextTick(function() {
							// 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
							this.imgHeight = document.body.clientWidth * 500 / 1920
						});
					}
				})
			},
			//获取公告
			getggcontent() {
				let data = {
					mark: 'notice01'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.ggcontent = res.data.code
					}
				})
			},
			//获取产品优势
			getadvantagelist() {
				let data = {
					mark: 'index02'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.advantagelist = res.data.data

					}
				})
			},
			//获取短信分类
			getdocumentlist() {
				let data = {
					mark: 'index03'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.documentlist = res.data.data
					}
				})
			},
			//获取文章列表
			getnewslist() {
				let data = {
					page: 1,
					page_size: 10000
				}
				// data
				this.$api.home.articleList(data).then(res => {
					if (res.code == 1) {
						this.newslist = res.data
					}
				})
			},
			//跳转文章详情
			godetails(id) {
				this.$router.push({
					path: '/newsdetails',
					query: {
						id: id
					}
				})
			}
		}
	}
</script>

<style scoped>
.frcs{
	display: flex;
	 align-content: center;
	flex-wrap: wrap; 
	margin-top: 20px;
}
.stitle{
	margin-top: 20px;
}
	.gg {
		width: 100%;
		height: 56px;
		background: #2F3337;
	
	
	}

	.gg .con {
		width: 1200px;
		height: 56px;
		margin: 0 auto;
		font-size: 16px;
		color: #ffffff;
	}

	.gg .con img {
		width: 24px;
		height: 24px;
		margin: 0 10px 0 50px;
	}

	.advantage {
		width: 1200px;
		margin: 100px auto;
	}

	.advantage .title {
		font-size: 32px;
		color: #24272C;
	}

	.advantage .title img {
		width: 32px;
		height: 35px;
		display: block;
		margin: 4px 10px 0 0;
	}

	.advantage .itembox {
		width: 1200px;
		margin-top: 30px;
		display: flex;
		flex-wrap: wrap;
	}

	.advantage .itembox .item {
		width: 340px;
		background: #ffffff;
		margin: 20px 29px;
		box-sizing: border-box;
		font-size: 16px;
		cursor: pointer;
	}

	.advantage .itembox .item img {
		width: 80px;
		display: block;
	}

	.advantage .itembox .item .t {
		font-size: 22px;
		color: #24272C;
		margin-bottom: 10px;
	}

	.advantage .itembox .item .p {
		font-size: 18px;
		color: #24272C;
		opacity: 0.8;
	}

	.trait {
		width: 100%;
		height: 240px;
		background: url('../assets/image/homebg.png') no-repeat;
		margin: 100px auto;
	}

	.trait .con {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
	}

	.trait .con .traititem {
		text-align: center;
		font-size: 24px;
		color: #ffffff;
	}

	.trait .con .traititem img {
		height: 72px;
		margin-bottom: 10px;
	}

	.document {
		width: 1200px;
		margin: 100px auto;
	}

	.document .documentitem {
		width: 370px;
		height: 438px;
		background: #FFFFFF;
		box-shadow: 0px 6px 18px 1px rgba(0, 0, 0, 0.16);
		box-sizing: border-box;
		padding-top: 40px;
		position: relative;
	}

	.document .documentitem .t {
		font-size: 24px;
		color: #24272C;
		text-align: center;
	}

	.document .documentitem .ioc {
		display: block;
		margin: 40px auto 36px auto;
	}

	.document .documentitem .p {
		width: 320px;
		height: 78px;
		font-size: 20px;
		color: #5A5C60;
		margin: 0 auto;
	}

	.document .documentitem .btn {
		width: 240px;
		height: 64px;
		background: #006DFF;
		border-radius: 8px;
		font-size: 20px;
		color: #ffffff;
		position: absolute;
		left: 70px;
		bottom: 32px;
		cursor: pointer;
	}

	.article {
		width: 1200px;
		margin: 100px auto;
	}

	.article .title {
		font-size: 32px;
		color: #24272C;
	}

	.article .title img {
		width: 32px;
		height: 35px;
		display: block;
		margin: 4px 10px 0 0;
	}

	.article .itembox {
		width: 1200px;
		background: #FFFFFF;
		box-shadow: 0px 6px 18px 1px rgba(0, 0, 0, 0.16);
		margin-top: 30px;
		padding: 23px 0;
	}

	.article .itembox .item {
		width: 1140px;
		height: 56px;
		background: #ffffff;
		margin: 10px auto;
		box-sizing: border-box;
		padding: 0 34px;
		font-size: 16px;
		cursor: pointer;
	}

	.article .itembox .item .name {
		width: 1000px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	.article .itembox .item:hover {
		background: #006DFF;
		color: #ffffff;
		transition: background 0.4s linear;
	}

	.article .itembox .item:hover img {
		content: url('../assets/image/xz-a.png');
	}
</style>