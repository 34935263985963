<template>
	<div>
		<div class="box">
			<div class="con">
				<div class="navbox fcs">
					<div class="nav">
						<div class="t">服务理念</div>
						<div>短信群发</div>
						<div>活动促销</div>
					</div>
					<div class="nav">
						<div class="t">联系我们</div>
						<div>客服邮箱</div>
						<div>izouxin@163.com</div>
					</div>
					<div class="nav">
						<div class="t">操作教程</div>
						<div>常见问题</div>
						<div>关于我们</div>
					</div>
				</div>
				<div class="yqbox fcc">
					<div class="yq fc">友情链接 ：</div>
					<div class="yq fc" @click="gopage('https://www.baidu.com/')">
						<img src="../assets/image/bd.png" alt="" style="width: 17px;height: 18px;margin-right: 10px;">
						<div>百度</div>
					</div>
					<div class="yq fc" @click="gopage('https://www.sina.com.cn/')">
						<img src="../assets/image/xl.png" alt="" style="width: 23px;height: 20px;margin-right: 10px;">
						<div>新浪</div>
					</div>
					<div class="yq fc" @click="gopage('https://m.163.com/')">
						<img src="../assets/image/wy.png" alt="" style="width: 30px;height: 17px;margin-right: 10px;">
						<div>网易</div>
					</div>
				</div>
				<div class="info fcs">
					<div>威京（北京）软件科技有限公司</div>
					<div @click="gopage('https://beian.miit.gov.cn')">京ICP备2021014233号</div>
					<div>地址：北京市朝阳区建国路108号横琴人寿9层</div>
					<div>电话：4000901196</div>
					<div>邮箱：izouxin@163.com</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Footer',
		props: {},
		data() {
			return {
				info: {}

			}
		},
		created() {
			// this.getinfo();
		},
			mounted() {
		
		
			
		},
		methods: {
			//获取展示信息
			getinfo() {
				this.$api.home.indexfoot().then(res => {
					if (res.code == 1) {
						this.info = res.data
					}
				})
			},
			gopage(url) {
				window.open(url, "_blank");
			}
		}
	}
</script>

<style scoped>
	.box {
		width: 100%;
		height: 340px;
		background: #24272C;
		box-sizing: border-box;
		padding-top: 40px;
	}

	.con {
		width: 1200px;
		margin: 0 auto;
	}

	.navbox .nav {
		width: 240px;
		font-size: 16px;
		color: #81868C;
		line-height: 30px;
		text-align: center;
		cursor: pointer;
	}

	.navbox .nav .t {
		font-size: 20px;
		color: #BCC2CC;
		margin-bottom: 26px;
	}

	.yqbox {
		height: 58px;
		font-size: 16px;
		color: #81868C;
		border-top: #81868C solid 1px;
		border-bottom: #81868C solid 1px;
		margin: 30px 0;
	}

	.yqbox .yq {
		margin: 0 100px;
		cursor: pointer;
	}

	.info {
		font-size: 16px;
		color: #81868C;
	}
</style>