<template>
	<div id="app">
		<keep-alive>
			<Header v-show="path != '/login'"></Header>
		</keep-alive>
		<router-view />
		<keep-alive>
			<Footer v-show="path != '/login'"></Footer>
		</keep-alive>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				path: ''
			}
		},
		watch: {
			$route(to, from) {
				this.path = to.path
			}
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style>
@import "./store/font_face.css";

@media screen and (max-width: 1200px) {
 
	html {
	
	width: 1200px;
	
	font-size: 12px;
	margin: 0 auto;
	
	}
	
}
	body {
		color: #333333;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	.fc {
		display: flex;
		align-items: center;
	}

	.fcc {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.fcs {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.fce {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}
</style>