import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'Home',
	component: () => import('../views/Home.vue')
}, {
	path: '/introduce',
	name: 'Introduce',
	component: () => import('../views/Introduce.vue')
}, {
	path: '/solution',
	name: 'Solution',
	component: () => import('../views/Solution.vue')
}, {
	path: '/price',
	name: 'Price',
	component: () => import('../views/Price.vue')
}, {
	path: '/aboutus',
	name: 'Aboutus',
	component: () => import('../views/Aboutus.vue')
}, {
	path: '/developer',
	name: 'Developer',
	component: () => import('../views/Developer.vue')
}, {
	path: '/newsdetails',
	name: 'Newsdetails',
	component: () => import('../views/Newsdetails.vue')
}, {
	path: '/login',
	name: 'Login',
	component: () => import('../views/Login.vue')
}]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	if (to.path != '/') {
		document.body.scrollTop = 0
		// firefox
		document.documentElement.scrollTop = 0
	} else {
		document.body.scrollTop = 0
		// firefox
		document.documentElement.scrollTop = 0
	}
	next()
})
export default router