import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import http from './request/api.js'

import Footer from '@/components/Footer'
import Header from '@/components/Header'
import Base64 from './utils/base64'
Vue.prototype.$Base64 = Base64
Vue.config.productionTip = false
Vue.prototype.$api = http;
Vue.use(ElementUI)
Vue.component('Header', Header)
Vue.component('Footer', Footer)
new Vue({
    router,
    store,
    beforeCreate: function() {
        console.log('beforeCreate')

        function isMobile() {
            let userAgentInfo = navigator.userAgent;
            let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
            let getArr = Agents.filter(i => userAgentInfo.includes(i));
            return getArr.length ? true : false;
        }
        console.log(isMobile())
        if (isMobile()) {
            // window.location.href = 'http://szlm.ycssdsh.cn/wap/'
        }
    },
    render: h => h(App)
}).$mount('#app')