<template>
	<div>
		<div class="navbox">
			<div class="nav fcs">
				<img src="../assets/image/logo.png" alt="" style="width: 91px;cursor: pointer;" @click="changenav('/')">
				<div class="fc">
					<div :class="path == '/'?'item-a fcc':'item fcc'" @click="changenav('/')">首页</div>
					<div :class="path == '/introduce'?'item-a fcc':'item fcc'" @click="changenav('/introduce')">产品服务</div>
					<div :class="path == '/solution'?'item-a fcc':'item fcc'" @click="changenav('/solution')">解决方案</div>
					<div :class="path == '/price'?'item-a fcc':'item fcc'" @click="changenav('/price')">短信价格</div>
					<div :class="path == '/developer'?'item-a fcc':'item fcc'" @click="changenav('/developer')">开发者中心</div>
					<div :class="path == '/aboutus'?'item-a fcc':'item fcc'" @click="changenav('/aboutus')">关于我们</div>
					<div class="btn1 fcc" v-if="userinfo == null" @click="changenav('/login')">登录</div>
					<div class="btn2 fcc" v-if="userinfo == null" @click="changenav('/login')">马上试用</div>
			
					<div class="fc" v-else>
						<!-- <div class="name">{{userinfo.nickname}}</div>
						<img class="tx" :src="userinfo.avatar" alt=""> -->
						<!-- <div class="btn2 fcc" style="margin-right: 20px;">进入后台</div> -->
						<el-button type='success'>  <a style="color: #ffffff;text-decoration: none;" :href='token'>进入后台</a> </el-button>
						<el-button type="danger"  @click="tuichu">退出登录</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Header',
		props: {},
		data() {
			return {
				path: '/',
				userinfo: null,
				token:""

			}
		},
		created() {

			
			this.changeroute();
		},
		mounted() {
		
		
			
		},
		watch: {
			$route(to, from) {
			
				this.changeroute();
				if (sessionStorage.getItem('user_info')) {
					this.userinfo = JSON.parse(sessionStorage.getItem('user_info'));
						console.log(sessionStorage.getItem('token'),'21321213');
				this.token= `http://zxadmin.zx3p.com/?Watercup=${this.$Base64.encode(sessionStorage.getItem('token'))}&cid=${this.$Base64.encode('1323215444')}&pid=${this.$Base64.encode('66699888452')}`
				} else {
					this.userinfo = null;
				}
			}
		},
		methods: {
			//改变页面路径
			changenav(path) {
				this.path = path;
				this.$router.push({
					path: path
				})
				sessionStorage.setItem('pagepath', path)
			},
			//获取当前页面路径
			changeroute() {
				let pagepath = sessionStorage.getItem('pagepath')
				if (pagepath) {
					this.path = pagepath
				} else {
					this.path = '/'
				}
			},
			//退出登录
			tuichu() {
				let that = this;
				that.$confirm('确定退出登录吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					sessionStorage.removeItem('token');
					sessionStorage.removeItem('user_info');
					this.$router.go(0)
				}).catch(() => {});
			}
		}
	}
</script>

<style scoped>
	.navbox {
		width: 100%;
		height: 70px;
		background: #24272C;
	}

	.navbox .nav {
		width: 1200px;
		height: 70px;
		margin: 0 auto;
		font-size: 16px;
		color: #ffffff;
		
	}

	.navbox .nav .item {
		width: 120px;
		height: 70px;
		cursor: pointer;
	}

	.navbox .nav .item-a {
		width: 120px;
		height: 70px;
		background: #006DFF;
		cursor: pointer;
	}

	.navbox .nav .item:hover {
		background: #006DFF;
		transition: background 0.3s linear;
	}

	.navbox .nav .btn1 {
		width: 64px;
		height: 32px;
		border-radius: 4px;
		border: 1px solid #FFFFFF;
		font-size: 16px;
		margin-left: 15px;
		cursor: pointer;
	}

	.navbox .nav .btn2 {
		width: 88px;
		height: 32px;
		background: #006DFF;
		border-radius: 4px;
		font-size: 16px;
		margin-left: 15px;
		cursor: pointer;
	}

	.navbox .nav .name {
		width: 100px;
		text-align: right;
		font-size: 16px;
		color: #ffffff;
		cursor: pointer;
	}

	.navbox .nav .tx {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		margin: 0 12px;
		cursor: pointer;
	}
</style>